import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Hr, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				King’s Cut
			</title>
			<meta name={"description"} content={"Досвідчені перукарі, стильні стрижки та царська атмосфера, щоб ви відчули себе королівською особою."} />
			<meta property={"og:title"} content={"King’s Cut"} />
			<meta property={"og:description"} content={"Досвідчені перукарі, стильні стрижки та царська атмосфера, щоб ви відчули себе королівською особою."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10:53:12.866Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10:53:12.866Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10:53:12.866Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10:53:12.866Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10:53:12.866Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10:53:12.866Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10:53:12.866Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="link2" />
			<Override slot="box1" />
		</Components.Header>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-16">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Box
				width="100%"
				lg-width="100%"
				margin="0px 0px 2rem 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					padding="0px 0px 50% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						src="https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10:53:12.866Z"
						position="absolute"
						width="100%"
						bottom="-165px"
						right="auto"
						min-height="100%"
						object-fit="cover"
						display="block"
						top="-21px"
						left="-7px"
						srcSet="https://smartuploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10%3A53%3A12.866Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10%3A53%3A12.866Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10%3A53%3A12.866Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10%3A53%3A12.866Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10%3A53%3A12.866Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10%3A53%3A12.866Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10%3A53%3A12.866Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				justify-content="space-between"
				width="58%"
				flex-direction="row"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				lg-justify-content="center"
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box display="flex" flex-direction="column" md-width="100%">
					<Text
						font="--headline2"
						lg-text-align="center"
						sm-font="--headline3"
						sm-margin="0px 0px 20px 0px"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						md-text-align="left"
					>
						Ласкаво просимо{" "}
						<br />
						до King’s Cut{" "}
						<br />
						Barbershop
					</Text>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				lg-order="1"
				display="flex"
				width="40%"
				flex-direction="row"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="space-between"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-padding="0px 16px 16px 16px"
			>
				<Box display="flex" flex-direction="column">
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						lg-text-align="center"
						md-text-align="left"
					>
						У King’s Cut Barbershop ми віримо, що догляд за собою – це не лише зовнішній вигляд, а й відчуття. Наша перукарня створена як притулок для сучасного чоловіка, місце, де ви можете втекти від буденності та зануритися у світ стилю, вишуканості та розкоші. Від моменту, коли ви заходите всередину, вас зустрічає атмосфера, яка відзначає мистецтво догляду, де кожна деталь створена для того, щоб покращити ваш досвід. Ми пишаємося тим, що пропонуємо послуги, які виходять за межі звичайного, забезпечуючи, щоб ви залишили наш салон з відчуттям впевненості у собі.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 50px 0" sm-padding="40px 0 30px 0" quarkly-title="Statistics-11">
			<Override
				slot="SectionContent"
				align-items="flex-start"
				flex-direction="row"
				md-flex-wrap="wrap"
				max-width="1440px"
			/>
			<Box padding="20px 20px 20px 20px" md-width="100%" width="33.333%">
				<Text margin="0px 0px 0px 0px" font="--headline2" color="--dark">
					Про нас
				</Text>
			</Box>
			<Box width="33.333%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text letter-spacing="-.06em" color="--dark" margin="0px 0px 0px 0px" font="--headline3">
					Професійні перукарі
				</Text>
				<Text margin="20px 0px 0px 0px" font="--base" color="--dark">
					Наша команда складається з висококваліфікованих майстрів, які оволоділи мистецтвом догляду. З роками досвіду та пристрастю до своєї справи вони виконують стрижки та стилі з точністю, що відповідає вашим індивідуальним уподобанням.
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text margin="20px 0px 0px 0px" font="--base" color="--dark">
					У King’s Cut Barbershop ми не просто надаємо послуги, ми створюємо стиль життя, де кожен чоловік відчуває себе королем.
					<br />
				</Text>
			</Box>
			<Box width="33.333%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text margin="0px 0px 0px 0px" font="--headline3" letter-spacing="-.06em" color="--dark">
					Розкішна атмосфера
				</Text>
				<Text color="--dark" margin="20px 0px 0px 0px" font="--base">
					Наша перукарня – це більше, ніж місце для стрижки – це досвід. Ми створили простір, який випромінює розкіш і комфорт, з м'якими кріслами, елегантним декором і привітною атмосферою.
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text color="--dark" margin="20px 0px 0px 0px" font="--base">
					Ми гарантуємо, що кожна деталь відповідає вашим очікуванням, забезпечуючи найвищий рівень обслуговування.
					<br />
				</Text>
			</Box>
		</Section>
		<Section padding="150px 0 150px 0" background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-4%20%281%29.jpg?v=2024-08-12T10:53:12.896Z) center/cover repeat scroll padding-box" sm-padding="80px 0 80px 0" quarkly-title="Product-7">
			<Box min-width="100px" min-height="100px" display="flex" justify-content="flex-end">
				<Box
					min-width="100px"
					min-height="100px"
					width="900px"
					background="rgba(255, 255, 255, 0.9)"
					padding="50px 35px 50px 35px"
					display="flex"
					align-items="center"
					flex-direction="column"
					md-padding="35px 35px 35px 35px"
					border-radius="30px"
					margin="0px auto 0px auto"
				>
					<Text margin="0px 0px 20px 0px" font="--headline3" text-align="center">
						King’s Cut Barbershop — місце, де кожен чоловік відчуває себе королем.
					</Text>
					<Text margin="0px 0px 35px 0px" text-align="center" font="normal 400 18px/1.5 --fontFamily-sansHelvetica">
						Кожна стрижка у нашій колекції поєднує у собі точність та стиль, що гарантує, що ви вийдете від нас з упевненістю та задоволенням. Від моменту, коли ви сідаєте у крісло, до моменту, коли ви залишаєте салон, ми піклуємось про кожну деталь.
					</Text>
					<Button
						background="--color-darkL1"
						padding="12px 30px 12px 30px"
						border-radius="25px"
						href="/services"
						type="link"
						text-decoration-line="initial"
					>
						Послуги
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				align-self="center"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Чому King’s Cut?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					У King’s Cut Barbershop ми виходимо за рамки звичайного, щоб забезпечити винятковий досвід догляду з кількома ключовими перевагами:
					<br />
					Майстерність: Кожен наш перукар – це висококваліфікований майстер своєї справи, який знає, як підкреслити вашу індивідуальність.
					<br />
					Розкішна атмосфера: Ми створили простір, який дарує комфорт та задоволення від кожного візиту.
					<br />
					Якість: Ми використовуємо тільки найкращі засоби для догляду, які підходять для вашого типу волосся та шкіри, забезпечуючи вам найкращі результати.
					<br />
					Персоналізований підхід: Кожен клієнт для нас – це особливий гість, і ми робимо все можливе, щоб ви почували себе королем.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/1-2.jpg?v=2024-08-12T10:53:12.857Z"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/66b9c1f6e778710018d287c5/images/1-2.jpg?v=2024-08-12T10%3A53%3A12.857Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1f6e778710018d287c5/images/1-2.jpg?v=2024-08-12T10%3A53%3A12.857Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1f6e778710018d287c5/images/1-2.jpg?v=2024-08-12T10%3A53%3A12.857Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1f6e778710018d287c5/images/1-2.jpg?v=2024-08-12T10%3A53%3A12.857Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1f6e778710018d287c5/images/1-2.jpg?v=2024-08-12T10%3A53%3A12.857Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1f6e778710018d287c5/images/1-2.jpg?v=2024-08-12T10%3A53%3A12.857Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1f6e778710018d287c5/images/1-2.jpg?v=2024-08-12T10%3A53%3A12.857Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text1" />
			<Override slot="text" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link1" />
			<Override slot="link" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6666e55e1d712a002334ca50"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});